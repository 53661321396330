const brakeRobotSide = {
  name: 'brake (robotside)',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'opening',
      duration: '3:10 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/opening',
        deezer: '',
      },
    },
    {
      trackname: 'leave or stay',
      duration: '4:58 min',
      tracknr: '02',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/leave-or-stay',
        deezer: '',
      },
    },
    {
      trackname: 'dark horse',
      duration: '3:09 min',
      tracknr: '03',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/dark-horse',
        deezer: '',
      },
    },
  ],
  date: '12-2019',
  bgColor: '#A8D3AF',
  cover: 'joevnoise_brake-robotside.png',
  detail_link: 'brake-robotside',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/album/brake-robotside',
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise',
    },
  ],
};

export default brakeRobotSide;
