import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  StyledH1,
  StyledCol,
  StyledP,
  StyledCaption,
  StyledIconP,
  StyledReleaseImage,
  StyledReleaseContainer,
  StyledSocialIcon,
} from '../styled.js';

export const Detail = (props) => {
  const { cover, bgColor } = props;
  return (
    <Col xs={12} md={12} lg={12}>
      <StyledReleaseImage
        style={{
          backgroundImage: `url(/assets/${cover})`,
          backgroundColor: `${bgColor}`,
        }}
      />
    </Col>
  );
};

export default Detail;
