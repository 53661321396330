import React from 'react';

import Overview from './views/Overview';
import Details from './views/Details';

const routes = {
  '/': () => <Overview />,
  '/details/': () => <Details />,
  '/details/:release': ({ release }) => <Details detail_link={release} />,
};

export default routes;
