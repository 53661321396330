module.exports = {
  active: '#39B54A',
  inactive: '#A6A6A6',
  clickable: '#8EE0F3',
  error: '#F95151',
  logo: '#48B2CA',
  darkText: '#0F252A',
  copyText: '#232829',
  light: '#D9E6E9',
  white: '#fefefe',
};
