const joevdata = {
  name: 'Fabian',
  surname: 'Althaus',
  bandname: 'joev noise',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com'
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise'
    },
    {
      spotify: 'https://joevnoise.page.link/Spotify'
    },
    {
      deezer: 'https://joevnoise.page.link/Deezer'
    },
    {
      facebook: 'https://facebook.com/joevnoise'
    },
    {
      instagram: 'https://instagram.com/joevnoise'
    },
    {
      twitter: 'https://twitter.com/joevnoise'
    }
  ]
};

export default joevdata;
