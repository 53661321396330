import React from 'react';
import { Col, Row, Container } from 'reactstrap';

import { ReactComponent as Gradient } from '../../gardient_test.svg';
import MediaButtons from '../MediaButtons';

import {
  Spacer,
  StyledJoevLogo,
  StyledH1Bold,
  StyledWelcomeContainer,
  StyledDownArrow
} from '../styled.js';

export const Welcome = ({
  handleLogoClicked,
  logoClicked,
  handleAnimationEnd,
  handleDownClicked,
  releaseAmount,
  joevData,
  bgColor
}) => (
  <>
    <Gradient />
    <Spacer />
    <StyledWelcomeContainer
      fluid
      className={'text-center'}
      style={{ height: `${releaseAmount}00vh` }}
    >
      <StyledJoevLogo
        onClick={handleLogoClicked}
        onAnimationEnd={handleAnimationEnd}
        className={logoClicked ? 'drawIn' : 'drawOut'}
      />

      <Row>
        <Col className={'text-center'}>
          <StyledH1Bold>this is joev noise</StyledH1Bold>
        </Col>
      </Row>
      <Spacer />

      <Spacer />
      <Row>
        <Col
          xs={12}
          md={12}
          lg={12}
          className={
            'd-flex justify-content-center align-items-center mediaButtonsXSStart'
          }
        >
          <MediaButtons
            links={joevData.links}
            isInViewport={true}
            bgColor={'rgba(80,220,80,0.8)'}
            spacing={'8px'}
            size={'0.8'}
          />
        </Col>
      </Row>

      <Spacer />
      <Row>
        <Col>
          <StyledH1Bold style={{ textAlign: 'center' }}>
            <StyledDownArrow
              className={'downArrow'}
              onClick={handleDownClicked}
            />
          </StyledH1Bold>
        </Col>
      </Row>
    </StyledWelcomeContainer>
  </>
);

export default Welcome;
