import React, { useRef, useState, useEffect } from 'react';

import { Row, Col } from 'reactstrap';
import MediaButtons from '../MediaButtons';

import useFadeTransition from '../useFadeTransition';

import {
  StyledH1,
  StyledCol,
  StyledP,
  StyledCaption,
  StyledIconP,
  StyledReleaseImage,
  StyledReleaseContainer,
  StyledReleaseBgRow,
  StyledSocialIcon
} from '../styled.js';

export const Release = React.forwardRef((props, ref) => {
  const {
    cover,
    bgColor,
    name,
    date,
    short_description,
    links,
    tracklist,
    handleClick
  } = props;
  const [isInViewport, targetRef] = useFadeTransition(ref);

  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const img = new Image();

    img.src = `/assets/${cover}`;
    const ctx = canvas.getContext('2d');
    ctx.canvas.width = window.innerWidth;
    ctx.canvas.height = window.innerHeight;
    var hRatio = canvas.width / img.width;
    var vRatio = canvas.height / img.height;
    var ratio = Math.min(hRatio, vRatio);
    img.onload = function() {
      var wrh = img.width / img.height;
      var newWidth = canvas.width;
      var newHeight = newWidth / wrh;
      if (newHeight > canvas.height) {
        newHeight = canvas.height;
        newWidth = newHeight * wrh;
      }
      var xOffset = newWidth < canvas.width ? (canvas.width - newWidth) / 2 : 0;
      var yOffset =
        newHeight < canvas.height ? (canvas.height - newHeight) / 2 : 0;
      ctx.filter = 'blur(6px)';
      ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
    };
    // ctx.clearRect(0, 0, window.innerHeight, window.innerWidth);
  });

  return (
    <StyledReleaseContainer fluid className={'align-items-end'}>
      <StyledReleaseBgRow style={{ backgroundColor: `${bgColor}` }}>
        <canvas
          className={isInViewport ? 'fadeInBg' : 'fadeOut'}
          ref={canvasRef}
          style={{
            transform: 'scale(1.2)',
            margin: 'auto'
          }}
        />
        <img ref={imageRef} src={`/assets/${cover}`} className="hidden" />
      </StyledReleaseBgRow>
      <Row>
        <Col xs={12} md={12} lg={12} style={{ marginTop: '10vh' }}>
          <StyledReleaseImage
            className={isInViewport ? 'visibleCover' : null}
            style={{
              backgroundImage: `url(/assets/${cover})`
            }}
            id={name}
            onClick={handleClick}
          />
        </Col>
        <StyledCol
          className={
            isInViewport
              ? 'visible mx-auto col-9 scaleDownHor'
              : 'hidden col-9 mx-auto scaleDownHor'
          }
        >
          <div
            // className={isInViewport ? 'visible' : 'hidden'}
            ref={targetRef}
          >
            <StyledH1>{name}</StyledH1>
            <StyledP>
              {short_description}
              {tracklist.length > 1
                ? `Tracks: ${tracklist.length}`
                : 'Single Release'}
            </StyledP>
            <StyledCaption>released: {date}</StyledCaption>
          </div>
        </StyledCol>
        <Col
          xs={12}
          md={3}
          lg={3}
          className={
            'd-flex justify-content-center align-items-center mediaButtonsXS'
          }
        >
          <MediaButtons
            bgColor={bgColor}
            links={links}
            isInViewport={isInViewport}
          />
        </Col>
      </Row>
    </StyledReleaseContainer>
  );
});
// <div
//   className={isInViewport ? 'blur' : 'unblur'}
//   style={{
//     backgroundPosition: 'center',
//     backgroundRepeat: 'no-repeat',
//     display: 'block',
//     transform: 'scale(1) rotate(180deg)',
//     width: '100%',
//     height: '100vh',
//     backgroundImage: `url(/assets/${cover})`,
//     backgroundSize: 'cover',
//     transition: 'all 3s ease'
//   }}
// />
export default Release;
