const clsr = {
  name: 'clsr',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'head down',
      duration: '5:53 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/head-down',
        deezer: ''
      }
    },
    {
      trackname: 'totally weird',
      duration: '4:43 min',
      tracknr: '02',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/totally-weird',
        deezer: ''
      }
    },
    {
      trackname: 'have to leave',
      duration: '2:59 min',
      tracknr: '03',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/have-to-leave',
        deezer: ''
      }
    },
    {
      trackname: 'imperfection',
      duration: '3:58 min',
      tracknr: '04',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/imperfection',
        deezer: ''
      }
    },
    {
      trackname: 'prty song',
      duration: '3:47 min',
      tracknr: '05',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/prty-song',
        deezer: ''
      }
    },
    {
      trackname: 's|ren (lp version)',
      duration: '3:26 min',
      tracknr: '06',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/s-ren-lp-version',
        deezer: ''
      }
    },
    {
      trackname: 'trying to (remix)',
      duration: '5:26 min',
      tracknr: '07',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/trying-to-remix',
        deezer: ''
      }
    }
  ],
  date: '01-2014',
  bgColor: '#959387',
  cover: 'joevnoise_clsr.png',
  detail_link: 'clsr',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/album/clsr'
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise/sets/clsr'
    }
  ]
};

export default clsr;
