import React from 'react';
import { Col } from 'reactstrap';

import {
  StyledH1,
  StyledCol,
  StyledP,
  StyledCaption,
  StyledIconP,
  StyledReleaseImage,
  StyledReleaseContainer,
  StyledSocialIcon
} from '../styled.js';

export const MediaButtons = ({
  links,
  isInViewport,
  bgColor,
  spacing,
  size
}) => {
  return (
    <>
      {links
        ? links.map((link, counter) => {
            let platform = Object.keys(links[counter])[0];
            let myLink = links[counter][platform];
            return (
              <StyledSocialIcon
                onClick={() => window.open(myLink, '_blank')}
                key={platform + myLink}
                className={isInViewport ? 'scaleIn' : 'scaleOut'}
                style={{
                  backgroundColor: bgColor,
                  margin: spacing ? spacing : '4px'
                }}
              >
                <img
                  alt={platform}
                  style={{
                    display: 'block',
                    width: '32px',
                    margin: 'auto',
                    paddingTop: '8px'
                    // filter: 'invert(1)'
                  }}
                  src={`/assets/icons/${platform}.png`}
                />
                <StyledIconP>{platform}</StyledIconP>
              </StyledSocialIcon>
            );
          })
        : null}
    </>
  );
};

export default MediaButtons;
