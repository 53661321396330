const outOfOrder = {
  name: 'out of order',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'out of order',
      duration: '02:34 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/out-of-order',
        deezer: ''
      }
    },
    {
      trackname: 'how to',
      duration: '03:48 min',
      tracknr: '02',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/how-to',
        deezer: ''
      }
    },
    {
      trackname: 'fight for rights',
      duration: '05:52 min',
      tracknr: '03',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/fight-for-rights',
        deezer: ''
      }
    },
    {
      trackname: 'whether be',
      duration: '04:19 min',
      tracknr: '04',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/whether-be',
        deezer: ''
      }
    },
    {
      trackname: 'rise up',
      duration: '05:14 min',
      tracknr: '05',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/rise-up',
        deezer: ''
      }
    },
    {
      trackname: 'inseparable',
      duration: '04:26 min',
      tracknr: '06',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/inseparable',
        deezer: ''
      }
    },
    {
      trackname: 'fearless world',
      duration: '09:58 min',
      tracknr: '07',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/fearless-world',
        deezer: ''
      }
    },
    {
      trackname: 'ahjo (bonus',
      duration: ' 03:0 min',
      tracknr: '08',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/ahjo-bonus',
        deezer: ''
      }
    }
  ],
  date: '04-2016',
  bgColor: '#89837f',
  cover: 'joevnoise_out-of-order.png',
  detail_link: 'out-of-order',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/album/out-of-order-2'
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise/sets/out-of-order'
    }
  ]
};

export default outOfOrder;
