const brakeDearSide = {
  name: 'brake (dearside)',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'wirbeln',
      duration: '7:58 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/wirbeln',
        deezer: '',
      },
    },
    {
      trackname: 'schatten',
      duration: '9:16 min',
      tracknr: '02',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/schatten',
        deezer: '',
      },
    },
    {
      trackname: 'unleash',
      duration: '5:49 min',
      tracknr: '03',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/unleash',
        deezer: '',
      },
    },
  ],
  date: '12-2019',
  bgColor: '#A8D3AF',
  cover: 'joevnoise_brake-dearside.png',
  detail_link: 'brake-dearside',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/album/brake-deerside',
    },
    {
      spotify: 'https://open.spotify.com/album/63HqcT9VMAfoiQD4lEmPSH',
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise',
    },
  ],
};

export default brakeDearSide;
