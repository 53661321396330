const arc = {
  name: 'arc',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'kindle',
      duration: '02:46 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/kindle',
        deezer: ''
      }
    },
    {
      trackname: 'blossoms green',
      duration: '04:16 min',
      tracknr: '02',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/blossoms green',
        deezer: ''
      }
    },
    {
      trackname: 'concret',
      duration: '03:20 min',
      tracknr: '03',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/concret',
        deezer: ''
      }
    },
    {
      trackname: 'light follows',
      duration: '04:56 min',
      tracknr: '04',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/light follows',
        deezer: ''
      }
    },
    {
      trackname: 'develo',
      duration: '06:00 min',
      tracknr: '05',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/develop',
        deezer: ''
      }
    }
  ],
  date: '02-2013',
  bgColor: '#d8c9af',
  cover: 'joevnoise_arc.png',
  detail_link: 'arc',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/album/arc-ep'
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise/sets/arc-ep'
    }
  ]
};

export default arc;
