const toughLove = {
  name: 'tough love',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'tough love',
      duration: '5:40 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/tough-love-sailor-i-tribute',
        deezer: ''
      }
    }
  ],
  date: '03-2020',
  bgColor: '#7eabcd',
  cover: 'joevnoise_tough-love.png',
  detail_link: 'tough-love',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/track/tough-love-sailor-i-tribute'
    },
    {
      spotify: 'https://joevnoise.page.link/spotify'
    },
    {
      soundcloud:
        'https://soundcloud.com/joevnoise/tough-love-joevnoise-sailorandi-tribute'
    }
  ]
};

export default toughLove;
