import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';

import { ReactComponent as JoevLogo } from '../logo.svg';
import { ReactComponent as DownArrow } from '../downArrow.svg';

export const StyledWelcomeContainer = styled(Container)`
  position: absolute;
  top: 0;
`;
export const StyledReleaseContainer = styled(Container)`
  display: block;
  height: 100vh;
  /* margin-bottom: 10%;
  margin-top: 10%; */
`;

export const StyledSocialIcon = styled.div`
  width: 64px;
  height: 64px;
  display: block;
  opacity: 0.9;
  transition: all 0.5s ease;
  /* mix-blend-mode: darken; */
  border-radius: 8px;
  z-index: 5000;
  @media (max-width: 768px) {
    &:hover {
      transform: scale(1.2) !important;
      transition: all 0.5s ease;
      box-shadow: 0px 0px 2px 2px rgba(255, 255, 255, 0.4);
      z-index: 5500;
      opacity: 1;
    }
  }
  @media (min-width: 768px) {
    &:hover {
      transform: scale(1.4) !important;
      transition: all 0.5s ease;
      box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, 0.4);
      z-index: 5500;
      opacity: 1;
    }
  }
  &:after {
    /* content: '';
    position: absolute;
    background: #333;
    border-radius: 8px;
    top: 0px;
    width: 64px;
    height: 64px;
    filter: invert(100%);
    mix-blend-mode: difference; */
  }
`;

export const StyledJoevLogo = styled(JoevLogo)`
  width: 25vh;
  position: sticky;
  top: 0;
  margin-top: 45vh;
  opacity: 1;
  z-index: 5500;
  @media (max-width: 768px) {
    margin-top: 25vh;
  }
`;

export const StyledDownArrow = styled(DownArrow)`
  width: 25vh;
  position: absolute;
  transform: translate(-50%, 0);
  left: calc(50%);
  bottom: 3vh;
  /* margin: 45vh auto; */
  opacity: 1;
  z-index: 5500;
`;

export const StyledH1Bold = styled.h3`
  padding: 0px 1rem;
  /* font-size: 3em; */
  font-weight: bold;
  color: rgba(255, 0, 255, 1);
  text-align: center;
  font-variant-caps: all-petite-caps;
  z-index: 5100;
  position: absolute;
  transform: translate(-50%, 0);
  width: 100%;
  left: 50%;
  /* top:45%; */
`;

export const StyledH1 = styled.h1`
  padding: 0.2em 0rem 0 0;
  /* font-size: 3em; */
  color: #fff;
  line-height: 0.75em;
  /* background-color: rgba(255,0,255,0.4); */
  font-variant-caps: all-petite-caps;
  transition: all 0.5s ease;
`;

export const StyledCol = styled(Col)`
  max-width: 100%;
  transform: translate(0);
  background-color: rgba(255, 0, 255, 0.4);
  transition: all 2s ease-out;
`;

export const StyledP = styled.p`
  font-variant-caps: all-petite-caps;
  /* box-shadow:2px 2px 16px 16px rgba(255,255, 255, 0.4); */
  padding: 0px 1rem;
  font-size: 2em;
  margin: 0px;
  color: #fff;
  /* background-color: rgba(255,0,255,0.4) */
`;
export const StyledCaption = styled(StyledP)`
  font-variant-caps: all-petite-caps;
  /* box-shadow:2px 2px 16px 16px rgba(255,255, 255, 0.4); */
  padding: 0px 1rem;
  font-size: 1.5em;
  color: #fff;
  /* background-color: rgba(255,0,255,0.4) */
`;
export const StyledIconP = styled.p`
  font-size: 0.6em;
  color: #fff;
  text-align: center;
  line-height: 0.6;
  margin-bottom: 0;
  margin-top: 0px;
  padding-top: 8px;
  padding-bottom: 4px;
  font-variant-caps: all-petite-caps;
  /* filter: invert(1); */
`;

export const Spacer = styled.p`
  height: 10vh;
  display: block;
  overflow: hidden;
  /* background: linear-gradient(
    10deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 60%,
    rgba(255, 0, 255, 0.8) 100%
  );
  &:before {
    content: '';
    background: radial-gradient(
      circle,
      rgba(255, 0, 255, 1) 0%,
      rgba(255, 255, 255, 0) 40%
    );
    top: -65%;
    left: -30%;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
  &:after {
    content: '';
    background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0) 50%,
      rgba(0, 255, 0, 1) 80%
    );
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
    top: -20%;
  } */
`;

export const StyledReleaseImage = styled.div`
  margin: auto;
  background-size: contain;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: inherit;
  height: 50vh;
  /* box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, 0); */
  opacity: 0.6;
  transition: all 2s ease;
  margin-bottom: 5vh;
  transform: scale(0.9);
`;

export const StyledReleaseBgRow = styled(Row)`
  position: absolute;
  width: 100%;
  height: 100vh;
  zindex: -1;
  opacity: 0.8;
  overflow: hidden;


  @media (min-width: 768px) {
    transform: translateY(-5vh);
  }
  @media (max-width: 768px) {
    transform: translateY(0vh);
    }
  }
`;
export default {
  Spacer,
  StyledJoevLogo,
  StyledDownArrow,
  StyledH1,
  StyledCol,
  StyledH1Bold,
  StyledP,
  StyledCaption,
  StyledIconP,
  StyledReleaseImage,
  StyledWelcomeContainer,
  StyledReleaseContainer,
  StyledReleaseBgRow,
  StyledSocialIcon
};
