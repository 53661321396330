import React, { useState, useEffect, createRef } from 'react';
import { navigate } from 'hookrouter';

import { Release, Welcome, Loading, Footer } from '../../components';

import { releaseData, joevData } from '../../data';

const myData = releaseData;

const Overview = props => {
  const [openDetails, setOpenDetails] = useState(false);
  const [releaseData, setReleaseData] = useState(myData);
  const [thisRelease, setThisRelease] = useState({});
  const [logoClicked, setLogoClicked] = useState(true);
  const [animationEnd, setAnimationEnd] = useState(false);

  useEffect(() => {
    if (openDetails) {
      navigate(`/details/${thisRelease.detail_link}`, true);
    }
  }, [openDetails]);

  const handleClick = event => {
    const myRelease = releaseData.filter(
      release => release.name === event.target.id
    );
    setThisRelease(releaseData[0]);
    // setOpenDetails(true);
  };

  const handleLogoClicked = () => {
    console.log('clicked');
    setLogoClicked(true);
    if (animationEnd) {
      setAnimationEnd(true);
    }
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleDownClicked = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  const handleAnimationEnd = () => {
    setLogoClicked(false);
    setAnimationEnd(true);
  };

  useEffect(() => {
    setAnimationEnd(false);
  }, [animationEnd]);

  return (
    <>
      <Welcome
        handleAnimationEnd={handleAnimationEnd}
        handleLogoClicked={handleLogoClicked}
        logoClicked={logoClicked}
        handleDownClicked={handleDownClicked}
        releaseAmount={releaseData.length + 1}
        joevData={joevData}
      />
      {releaseData ? (
        <>
          {releaseData.map((release, i) => (
            <Release
              {...release}
              isInViewport
              ref={() => createRef()}
              key={release.name}
              handleClick={handleClick}
            />
          ))}
        </>
      ) : (
        <Loading />
      )}
      <Footer />
    </>
  );
};

export default Overview;
