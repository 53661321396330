import React, { useState, useEffect } from 'react';
import { navigate } from 'hookrouter';
import { Row, Col, Container, Button } from 'reactstrap';
import styled from 'styled-components';
import colors from '../../color.js';

import { releaseData } from '../../data';

import { Detail } from '../../components';

const myData = releaseData;
const StyledH1 = styled.h3`
  margin-top: 1em;
  text-align: center;
  padding: 0px 1rem;
  font-size: 1.5em;
`;

const StyledH1Bold = styled(StyledH1)`
  font-weight: 700;
  color: ${colors.logo};
`;

const StyledH4 = styled.h4`
  margin: 1.5em 0px;
  text-align: center;
  padding: 0px 1rem;
  font-size: 1.25em;
`;

const Details = (props) => {
  const [detailLink, setDetailLink] = useState(props['detail_link']);
  const [detail, setDetail] = useState({});
  const [startBooking] = useState(false);

  const myRelease = () => {
    const thisRelease = releaseData.filter((release) => release.detail_link === detailLink);
    setDetail(thisRelease[0]);
  };

  useEffect(() => {
    myRelease();
  }, [detailLink]);
  return (
    <>
      {Object.keys(detail).length > 1 ? (
        <Container
          fluid
          style={{
            background: detail.bgColor,
          }}
        >
          <Detail />
          <Row>
            {console.log(detail.cover)}
            <img alt={detail.name} src={`/assets/${detail.cover}`} />
            <Col>
              <StyledH1Bold>{detail.name}</StyledH1Bold>
              <StyledH4>{detail.description}</StyledH4>
              <StyledH4>{detail.date}</StyledH4>
            </Col>
          </Row>
          <Row />
        </Container>
      ) : null}
    </>
  );
};

export default Details;
