import React, { useRef, useState, useEffect } from 'react';
import gsap from 'gsap';
import useIsInViewport from 'use-is-in-viewport';

export default (ref) => {
  const [isInViewport, targetRef] = useIsInViewport({
    target: ref,
  });

  const [timeline] = useState(gsap.timeline({ paused: true }));
  useEffect(() => {
    timeline.fromTo(
      ref.current,
      1,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
      }
    );
  }, []);
  useEffect(() => {
    isInViewport ? timeline.play() : timeline.reverse();
  }, [isInViewport]);
  return [isInViewport, targetRef];
};
