import React from 'react';
import { Col, Row, Container } from 'reactstrap';

import { StyledCaption } from '../styled.js';

export const Footer = () => (
  <>
    <Container fluid>
      <Row>
        <Col>
          <StyledCaption
            style={{
              color: '#f0f',
              opacity: 0.8
            }}
          >
            copyright 2020 by
            <span
              style={{
                cursor: 'pointer'
              }}
              onClick={() => window.open('https://fabianalthaus.de', '_blank')}
            >
              {' '}
              Fabian Althaus
            </span>
            , alias Joev Noise. all rights reserved.
          </StyledCaption>
        </Col>
      </Row>
    </Container>
  </>
);

export default Footer;
