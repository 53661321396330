const flashOfEnergy = {
  name: 'flash of energy',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'flash of energy',
      duration: '04:58 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/flash-of-energy',
        deezer: ''
      }
    },
    {
      trackname: 'a look-alike',
      duration: '03:21 min',
      tracknr: '02',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/a-look-alike',
        deezer: ''
      }
    },
    {
      trackname: 'hunt',
      duration: '04:10 min',
      tracknr: '03',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/hunt',
        deezer: ''
      }
    },
    {
      trackname: 'freedom',
      duration: '05:43 min',
      tracknr: '04',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/freedom',
        deezer: ''
      }
    },
    {
      trackname: '(reprise',
      duration: ' 03:4 min',
      tracknr: '05',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/reprise',
        deezer: ''
      }
    }
  ],
  date: '05-2012',
  bgColor: '#3f423c',
  cover: 'joevnoise_flash-of-energy.png',
  detail_link: 'flash-of-energy',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/album/flash-of-energy-ep'
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise/sets/flash-of-energy-ep'
    }
  ]
};

export default flashOfEnergy;
