import React from 'react';
import { Container } from 'reactstrap';

export const Loading = () => {
  return (
    <>
      <Container>Loading releases ...</Container>
    </>
  );
};

export default Loading;
