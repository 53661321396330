import React from 'react';
import { useRoutes } from 'hookrouter';

import Routes from './routes';

export default function App() {
  const routeResult = useRoutes(Routes);
  return <>{routeResult || <> no page found </>}</>;
}

// <Route path="/topics">
//   <Topics useParams useRouteMatch/>
// </Route>
