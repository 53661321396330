const purity = {
  name: 'purity (... and the beast)',
  short_description: '',
  description: '',
  tracklist: [
    {
      trackname: 'latency morning',
      duration: '05:37 min',
      tracknr: '01',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/latency-morning',
        deezer: ''
      }
    },
    {
      trackname: 'pixeled memories',
      duration: '03:44 min',
      tracknr: '02',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/pixeled-memories',
        deezer: ''
      }
    },
    {
      trackname: 'happy wake up',
      duration: '03:34 min',
      tracknr: '03',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/happy-wake-up',
        deezer: ''
      }
    },
    {
      trackname: 'beast',
      duration: '04:37 min',
      tracknr: '04',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/beast',
        deezer: ''
      }
    },
    {
      trackname: 'redeem',
      duration: '07:07 min',
      tracknr: '05',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/redeem',
        deezer: ''
      }
    },
    {
      trackname: 'counting seconds',
      duration: '04:24 min',
      tracknr: '06',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/counting-seconds',
        deezer: ''
      }
    },
    {
      trackname: '... and in time we meet agai',
      duration: '07:1 min',
      tracknr: '07',
      directLink: {
        soundcloud: '',
        spotify: '',
        bandcamp: 'https://joev.bandcamp.com/track/and-in-time-we-meet-again',
        deezer: ''
      }
    }
  ],
  date: '09-2017',
  bgColor: '#696969',
  cover: 'joevnoise_purity-and-the-beast.png',
  detail_link: 'purity-and-the-beast)',
  links: [
    {
      bandcamp: 'https://joev.bandcamp.com/album/purity-and-the-beast'
    },
    {
      soundcloud: 'https://soundcloud.com/joevnoise/sets/purity_and_the_beast'
    }
  ]
};
export default purity;
